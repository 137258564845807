<template>
  <div style="height: 1000px">
    <el-empty description="待开发"></el-empty>
  </div>
</template>

<script>

export default {
  name: "FrequencyRemindOther",
}
</script>

<style scoped>

</style>