import { render, staticRenderFns } from "./FrequencyRemindOther.vue?vue&type=template&id=6ab7df64&scoped=true"
import script from "./FrequencyRemindOther.vue?vue&type=script&lang=js"
export * from "./FrequencyRemindOther.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ab7df64",
  null
  
)

export default component.exports